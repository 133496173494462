@import "@/styles/bootstrap/scss/_variables.scss";

.dashboard-tab {
    background: none;
    padding: 12px 0;
    .container-v2 {
        max-width: 1400px;
        margin: 0 auto;
        width: auto;
        .dashboard-tab__list {
            display: flex;
            align-items: end;
            text-align: center;
            padding: 0 150px;
             a {
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                width: calc(100% / 3);
                color: #007bff;
                text-decoration: none;
                background-color: transparent;
                outline: none;
                cursor: pointer;
                button {
                    font-weight: bold;
                    color: #fff;
                    background: transparent;
                    border: 0;
                    position: relative;
                    display: flex;
                    align-items: center;
                    text-transform: none;
                    overflow: visible;
                    border-radius: 0;
                    outline: none;
                    cursor: pointer;
                }
                // .after-button::after {
                //     content: "";
                //     width: 60px;
                //     height: 2px;
                //     background: #fff;
                //     position: absolute;
                //     top: 50%;
                //     left: 150%;
                //     opacity: 1;
                // }
                
            }
        }
    }
   
}
@media only screen and (max-width: 1400px) {
    .container-v2 { 
        max-width: 100% !important;
    }
  .hide-status {
    display: none !important;
  }
  .after-button::after {
    display: none;
  }
  .dashboard-tab__list {
    flex-direction: column;
    padding: 0 !important;
    a {
        width: 100% !important;
    }
  }
}
